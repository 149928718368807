import React from 'react';
import classNames from 'classnames';
import Link from '../Link/Link';
import Icon from '../Icon/Icon.js';
import styles from './Button.module.scss';

const Button = ({ 
    className, 
    children, 
    iconName, 
    isReverse, 
    isPrimary, 
    isBlock,
    onClick, 
    pathname, 
    ...other
}) => {
    const buttonClass = classNames( styles.button, className, {
        [styles.isReverse]: isReverse,
        [styles.isPrimary]: isPrimary,
        [styles.isBlock]: isBlock
    });

    if( onClick ) {
        return (
            <button className={buttonClass} type="button" onClick={onClick} {...other}>
                {children} {iconName && <Icon className={styles.icon} name={iconName}/>}
            </button>
        ) 
    } else if( pathname ) {
        return (
            <Link className={buttonClass} to={pathname} {...other}>
                {children} {iconName && <Icon className={styles.icon} name={iconName}/>}
            </Link>
        ) 
    } else {
       return (
            <button className={buttonClass} type="submit" {...other}>
                {children} {iconName && <Icon className={styles.icon} name={iconName}/>}
            </button>
       )
    }
}

export default Button;