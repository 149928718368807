function mapLinks(obj, pages) {
    const newObj = JSON.parse(JSON.stringify(obj));

    function findMatch( pages, id ){
       return pages.find( page => page.frontmatter.id === id )
    }

    for (var k in newObj) {
        if (typeof newObj[k] === "object" && newObj[k] !== null) {
            newObj[k] = mapLinks(newObj[k], pages);
        } else {
            if (typeof newObj[k] === 'string' && newObj[k].startsWith('page-')) {
                const page = findMatch(pages, newObj[k]);

                if (page) {
                    newObj[k] = page.fields.slug
                }
            }
        }
    }

    return newObj;
}

function flatten({ frontmatter, fields, html, ...other }) {
    return {
        ...frontmatter,
        ...fields,
        html,
        ...other
    }
}

function parse( data ) {
    const parsed = { ...data };

    if( data && data.fields && data.fields.pages ) {
        parsed.frontmatter = mapLinks( data.frontmatter, data.fields.pages );
    }

    return flatten( parsed );
}


export default function parseMarkdown(data) {
    if (Array.isArray(data)) {
        return data.map(obj => parse( obj ));
    } else {
        return parse( data );
    }
}