import React, { useState } from 'react';
import Button from '../UI/Button/Button';
import Flyout from '../UI/Flyout/Flyout';
import { HTMLContent } from '../Content';
import styles from './EnquiryFlyout.module.scss';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const EnquiryFlyout = ({ title, intro, successMsg, ...other }) => {
    const [ isSubmitted, setSubmitted ] = useState( false );

    function handleSubmit( e ){
        e.preventDefault();
        const data = {};
        
        new FormData(e.target).forEach((value, key) => { data[key] = value });

        fetch( e.target.action , {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode( data )
        })
        .then( response => {
            if ( response.status !== 200 && response.status !== 201 ) {
                console.error(`Looks like there was a problem. Status Code: ${response.status}`);
                return;
            }

            setSubmitted( true );
        })
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });
    }

    return (
        <Flyout {...other}>
            <h2 className={styles.title}>{title}</h2>
            <HTMLContent className={styles.lead} content={intro} Tag="p"/>

            { !isSubmitted ?
                <form className={styles.form} name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={ handleSubmit }>
                    <div className={styles.row}>
                        <label className={styles.label} htmlFor="name">Full name</label>
                        <input className={styles.input} type="text" id="name" name="name" required />
                    </div>

                    <div className={styles.row}>
                        <label className={styles.label} htmlFor="email">Your email</label>
                        <input className={styles.input} type="email" id="email" name="email" required/>
                    </div>
        
                    <div className={styles.row}>
                        <label className={styles.label} htmlFor="telephone">Telephone (optional)</label>
                        <input className={styles.input} type="tel" id="telephone" name="telephone" />
                    </div>

                    <div className={styles.row}>
                        <label className={styles.label} htmlFor="budget">Budget (optional)</label>
                        <select className={styles.select} id="budget" name="budget">
                            <option value="">Please select...</option>
                            <option value="0-10k">0-10k</option>
                            <option value="10k-30k">10k-30k</option>
                            <option value="30k-60k">30k-60k</option>
                            <option value="60k+">60k+</option>
                        </select>
                    </div>

                    <div className={styles.row}>
                        <label className={styles.label} htmlFor="message">Your message</label>
                        <textarea className={styles.textarea} id="message" name="message" rows="10" required />
                    </div>
        
                    <input name="bot-field" type="hidden"/>
                    <input name="form-name" type="hidden" value="contact"/>

                    <Button isPrimary={true} isBlock={true}>Request consultation</Button>
                </form>
        
                :
        
                <p className={styles.alert}>
                    <strong>{successMsg}</strong>
                </p>
            }
        </Flyout>
    )
}

EnquiryFlyout.propTypes = {

};

export default EnquiryFlyout;
