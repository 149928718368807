import React, { useState, useContext } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import ThemeContext from "../context/ThemeContext";
import Meta from './Meta';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import EnquiryFlyout from './EnquiryFlyout/EnquiryFlyout';
import parseMarkdown from '../utils/parseMarkdown';
// import {ssrFadeout} from 'react-reveal/globals';

import "normalize.css";
import "../styles/fonts.scss";
import "../styles/base.scss";
import "../styles/transitions.scss";

import styles from './Layout.module.scss';

// ssrFadeout(false);

export default ({ children, meta, title, theme }) => {
    return (
        <StaticQuery
            query={graphql`
                query LayoutQuery {
                    metadata: markdownRemark(frontmatter: { id: { eq: "settings-meta" } }) {
                        frontmatter {
                            siteTitle
                            siteDescription
                            socialMediaCard {
                                publicURL
                            }
                        }
                    }

                    contact: markdownRemark(frontmatter: { id: { eq: "settings-contact" } }) {
                        frontmatter {
                            tel
                            email
                            cta
                            enquiryForm {
                                successMsg
                                title
                                intro
                            }
                        }
                    }

                    footer: markdownRemark(frontmatter: { id: { eq: "settings-footer" } }) {
                        frontmatter {
                            videoSrc
                            labels {
                                officesHeading
                                contactHeading
                            }
                            navItems {
                              fields {
                                  slug
                              }
                              frontmatter {
                                  name
                              }
                          }
                        }
                    }
                }
            `}
            render={data => {
                const { siteTitle, socialMediaCard } = data.metadata.frontmatter || {};
                const contactData = data.contact.frontmatter;
                const { current } = useContext(ThemeContext);
                const [isEnquiryActive, setEnquiryActive] = useState(false);
                const footerData = parseMarkdown(data.footer);

                return (
                    <div style={ current || theme}>
                        <Header tel={contactData.tel} onEnquiryBtnClick={e => setEnquiryActive(true)} />
                        <Helmet
                            defaultTitle={siteTitle}
                            titleTemplate={`%s | ${siteTitle}`}
                            htmlAttributes={{
                              lang: 'en-GB',
                              class: typeof window === 'undefined' ? 'no-js' : styles.scrollbar
                            }}
                        >
                            <title>{title}</title>
                            <link href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800&display=swap" rel="stylesheet"></link>
                        </Helmet>

                        <Meta
                            absoluteImageUrl={
                                socialMediaCard &&
                                socialMediaCard.publicURL
                            }
                            {...data.metadata.frontmatter}
                            {...meta}
                        />

                        <main className={styles.main}>
                            {children}
                        </main>

                        <EnquiryFlyout {...contactData.enquiryForm} isActive={isEnquiryActive} onCloseClick={e => setEnquiryActive(false)} />

                        <Footer {...contactData} {...footerData} />
                    </div>
                )
            }}
        />
    )
}

export const query = graphql`
  fragment Pages on MarkdownRemark {
    fields {
      pages {
        fields {
          slug
        }
        frontmatter {
          id
          name
          title
        }
      }
    }
  }

  fragment Testimonial on MarkdownRemark {
    frontmatter {
      testimonial {
        frontmatter {
          quote
          title
          jobTitle
        }
      }
    }
  }

  fragment SectionBase on MarkdownRemark {
    frontmatter {
      sections {
        id
        frontmatter {
          type
          theme
        }
      }
    }
  }

  fragment SectionClients on MarkdownRemark {
    frontmatter {
      sections {
        frontmatter {
          title
          items {
            id
            extension
            childImageSharp {
              fluid(maxWidth: 130, duotone: {
                highlight: "#ffffff",
                shadow: "#001D4D"
              }) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }

  fragment SectionFeature on MarkdownRemark {
    frontmatter {
      sections {
        frontmatter {
          name
          title
          desc
          link {
            text
            url
          }
          image {
            fit
            position
            id
            src {
              publicURL
              extension
            }
          }
          containerStyle
        }
      }
    }
  }

  fragment SectionTestimonials on MarkdownRemark {
    frontmatter {
      sections {
        frontmatter {
          testimonials {
            id
            frontmatter {
              quote
              title
              jobTitle
            }
          }
        }
      }
    }
  }

  fragment Sections on MarkdownRemark {
    ...SectionBase
    ...SectionFeature
    ...SectionClients
    ...SectionTestimonials
  }
`;