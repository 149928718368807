import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import Link from '../UI/Link/Link';
import Icon from '../UI/Icon/Icon.js';
import parseMarkdown from '../../utils/parseMarkdown';
import fixSlugs from '../../utils/fixSlugs';
import Dropdown from '../UI/Dropdown/Dropdown';
import styles from './Navbar.module.scss';

const Navbar = ({ className, disableDropdowns, onEnquiryBtnClick }) => {
    return (
        <StaticQuery
            query={graphql`
                query NavbarQuery {                    
                    whatWeDo: markdownRemark(frontmatter: { id: { eq: "page-what-we-do" } }) {
                        ...Pages
                        frontmatter {
                            specialities {
                                title
                                desc
                                link
                                img {
                                    publicURL
                                    extension
                                }
                            }
                            services {
                                title
                                link
                            }
                        }
                    }
                }
            `}
            render={({ whatWeDo }) => {
                const data = parseMarkdown( whatWeDo );

                return (
                    <ul className={classNames(styles.list, className)}>
                        <li className={styles.listItem}>
                            {disableDropdowns ?
                                <Link className={styles.listItemLink} to="/what-we-do/">What we do</Link>
                            :
                                <Dropdown 
                                    toggleClass={styles.listItemBtn} 
                                    menuClass={styles.dropdown}
                                    toggle={
                                        <>What we do <Icon name="chevron"/></>
                                    }>

                                    <ul className={styles.menu}>
                                        { data.specialities.map( (item, index) => !item.link ? null : (
                                            <li className={styles.menuItemPrimary} key={index}>
                                                <Link className={styles.menuItemLink} to={fixSlugs(item.link)}>
                                                    {item.img &&
                                                        <div className={styles.menuItemImg}>
                                                            <img src={item.img.publicURL} alt="" loading="lazy"/>
                                                        </div>
                                                    }
                                                    <div className={styles.menuItemBody}>
                                                        <strong className={styles.menuItemTitle}>
                                                            {item.title}
                                                        </strong>
                                                        <p className={styles.menuItemDesc}>{item.desc}</p>
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                        { data.services.map( (item, index) => !item.link ? null : (
                                            <li className={styles.menuItem} key={index}>
                                                <Link className={styles.menuItemLink} to={fixSlugs(item.link)}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>

                                </Dropdown>
                            }
                        </li>
                        <li className={styles.listItem}>
                            <Link className={styles.listItemLink} to="/work/">Work</Link>
                        </li>
                        <li className={styles.listItem}>
                            <Link className={styles.listItemLink} to="/about/">About</Link>
                        </li>
                        <li className={styles.listItem}>
                            <Link className={styles.listItemLink} to="/contact/">Contact</Link>
                        </li>
                        <li className={styles.listItem}>
                            <button className={styles.listItemLinkCta} onClick={onEnquiryBtnClick}>Start project</button>
                        </li>
                    </ul>
                )
            }}
            />
    )
}

Navbar.propTypes = {

};

export default Navbar;
