import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon.js';
import styles from './Flyout.module.scss';

const Flyout = ({ children, isActive, onCloseClick }) => {
    const ref = useRef(null);
    const flyoutClass = classNames(styles.flyout, {
        [styles.flyoutActive]: isActive
    });
    const overlayClass = classNames(styles.overlay, {
        [styles.overlayActive]: isActive
    });

    function handleDocumentClick( e ){
        if( ref.current && !ref.current.contains( e.target ) && isActive ) {
            onCloseClick()
        }
    }

    useEffect(() => {
        document.addEventListener('mouseup', handleDocumentClick );

        return () => {
            document.removeEventListener('mouseup', handleDocumentClick );
        }
    });

    useEffect( () => {
        if( isActive ) {
            document.documentElement.style.overflow = 'hidden';
        }

        return () => {
            document.documentElement.style.overflow = '';
        }
    },[ isActive ])

    return (
        <>
            <div className={flyoutClass} ref={ref}>
                <button className={styles.close} type="button" onClick={onCloseClick} aria-label="Close">
                    <Icon name="close"/>
                </button>
                <div className={styles.inner}>
                    {children}
                </div>
            </div>
            <div className={overlayClass}></div>
        </>
    )
}

Flyout.propTypes = {

};

export default Flyout;
