import React from 'react';
import classNames from 'classnames';
import preventOrphan from '../../../utils/preventOrphan';
import styles from './Hgroup.module.scss';

const Hgroup = ( { 
  lead, 
  title, 
  isReverse, 
  isPrimary, 
  TitleTag,
  preventOrphans
}) => {
  const className = classNames( styles.hgroup, {
    [styles.hgroupReverse]: isReverse,
    [styles.hgroupPrimary]: isPrimary
  });

  return (
    <header className={className}>
        {lead && title &&
          <strong className={styles.lead}>{lead}</strong>
        }
      
        { preventOrphans ?
          <TitleTag className={styles.title} dangerouslySetInnerHTML={{ __html: preventOrphan(title || lead || '') }}/>
          :
          <TitleTag className={styles.title}>{title || lead}</TitleTag>
        }      
    </header>
  )
}

Hgroup.defaultProps = {
  TitleTag: 'h1',
  preventOrphans: false
};

Hgroup.propTypes = {
  
};

export default Hgroup;
