import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Icon.module.scss';
import sprite from '../../../img/icons.svg';

const Icon = (props) => (
    <svg className={classnames(styles.icon, props.className)} style={props.style}>
        <use xlinkHref={`${sprite}#${props.name}`}></use>
    </svg>
);

Icon.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    style: PropTypes.object
};

export default Icon;