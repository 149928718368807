import React from 'react'
import { themes } from "../../context/ThemeContext";
import Link from '../UI/Link/Link';
import VideoBg from '../VideoBg/VideoBg';
import Section from '../Section/Section';
import NavList from '../UI/NavList/NavList';
import Fade from 'react-reveal/Fade';
import logo from '../../img/mrzen-logo.svg';
import styles from './Footer.module.scss';

const Footer = ({ tel, email, cta, labels, videoSrc, navItems }) => {
    return (
        <Section className={styles.footer} tag="footer" theme={themes.LIGHT} isCompact={true}>
            <div className={styles.inner}>
                <img className={styles.logo} src={logo} alt="Mr Zen" loading="lazy"/>

                <Fade bottom>
                    <div className={styles.col}>
                        <h2 className={styles.heading}>{labels.officesHeading}</h2>
                        <NavList items={[
                            { label: 'United Kingdom', link: '/contact/' },
                            { label: 'Australia', link: '/contact/' },
                        ]}/>
                    </div>
                </Fade>
                
                <Fade bottom>
                    <div className={styles.col}>
                        <h2 className={styles.heading}>{labels.contactHeading}</h2>
                        <NavList items={[
                            { label: tel, link: `tel:${tel.replace(/ |\(0\)/g, '')}` },
                            { label: email, link: `mailto:${email}` },
                        ]}/>
                        <p className={styles.cta}><strong>{cta}</strong></p>
                    </div>
                </Fade>

            </div>

            <div className={styles.smallprint}>
                <p className={styles.copyright}>Copyright {new Date().getFullYear()}. All rights reserved.</p>

                <ul className={styles.navlistInline}>
                    {navItems.map( item => (
                        <li key={item.fields.slug}>
                            <Link to={item.fields.slug}>{item.frontmatter.name}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            
            {videoSrc && 
                <VideoBg className={styles.video} src={videoSrc} isLazy={true} />
            }
        </Section>
    )
}

export default Footer
