import React from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid'; 
import Link from '../Link/Link';
import isExternalLink from '../../../utils/isExternalLink.js';
import styles from './NavList.module.scss';

const NavList = ({ items, isPrimary }) => {
    const classnames = classNames( styles.navlist, {
        [styles.isPrimary]: isPrimary
    });
    
    return (
        <ul className={classnames}>
            {items.map(item =>
                <li key={v4()}>
                    { isExternalLink( item.link ) || !item.link.startsWith('/') ? 
                        <a href={item.link}>{item.label}</a>
                        :
                        <Link to={item.link}>{item.label}</Link>
                    }
                </li>
            )}
        </ul>
    )
}

NavList.propTypes = {

};

export default NavList;
