import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import Navbar from '../Navbar/Navbar';
import Link from '../UI/Link/Link';
import Icon from '../UI/Icon/Icon';
import Button from '../UI/Button/Button';
import Logo from '../../img/mrzen-logo.inline.svg';
import styles from './Header.module.scss';

const Header = ({ tel, delta, onEnquiryBtnClick }) => {
    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    });
    const [ isNavbarVisible, setNavbarVisible ] = useState(false);
    const [ isScrolled, setScrolled ] = useState(false);

    const className = classNames( styles.header, {
        [styles.isNavbarVisible]: isNavbarVisible,
        [styles.isScrolled]: isNavbarVisible && isScrolled,
    });

    useEffect(() => {        
        if( isTabletOrMobile && isNavbarVisible ) {
            document.documentElement.style.overflow = 'hidden';
        }

        return () => {
            if( isTabletOrMobile && isNavbarVisible ) {
                document.documentElement.style.overflow = '';
            }
        }
    }, [isNavbarVisible, isTabletOrMobile]);

    useEffect(() => {       
        setNavbarVisible(!isTabletOrMobile);

        let prevScrollTop = 0;

        function handleScroll(e){
            let scrollTop = ( document.scrollingElement || document.documentElement ).scrollTop;

            if ( scrollTop < 0 ) scrollTop = 0;
            if( Math.abs( prevScrollTop - scrollTop ) <= delta ) return;

            if( !isTabletOrMobile ) {
                setNavbarVisible( scrollTop < prevScrollTop );
                setScrolled( scrollTop >= 90 );
            }

            prevScrollTop = scrollTop;
        }

        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [isTabletOrMobile, delta]);
    
    return (
        <header className={className} role="banner">
            <Link className={styles.logoLink} to="/" aria-label="Home">   
                <Logo className={isNavbarVisible ? '': styles.logoTextHide }/>
            </Link>
            
            <div className={styles.navbar}>
                <Navbar className={styles.nav} disableDropdowns={isTabletOrMobile} onEnquiryBtnClick={onEnquiryBtnClick}/>

                <a className={styles.tel} href={`tel:${tel.replace(/ |\(0\)/g, '')}`}>
                    <Icon className={styles.telIcon} name="phone"/> {tel}
                </a>

                <Button className={styles.button} iconName="arrow-right" onClick={onEnquiryBtnClick}>Start project</Button>
            </div>

            <button className={styles.navbarToggle} onClick={e => setNavbarVisible(!isNavbarVisible)} aria-label="Toggle menu" aria-expanded={isNavbarVisible}>
                <span className={styles.hamburger}/>
            </button>
        </header>
    )
};

Header.defaultProps = {
    delta: 25
};

export default Header;