import React, { useRef, useEffect, useContext } from 'react';
import classNames from 'classnames';
import ThemeContext from "../../context/ThemeContext";
import useIntersect from '../../hooks/useIntersect';
import styles from './Section.module.scss';

const Section = ({ 
  Tag, 
  children, 
  theme, 
  isCompact, 
  isUnstyled, 
  containerStyle,
  ...other
}) => {
  const ref = useRef(null);
  const { setTheme } = useContext(ThemeContext);
  const isIntersecting = useIntersect(ref);
  const className = classNames(other.className, {
    [styles.section]: !isUnstyled && !isCompact,
    [styles.sectionCompact]: !isUnstyled && isCompact,
    [styles.isDefault]: !containerStyle || containerStyle === 'DEFAULT',
    [styles.isSecondary]: containerStyle === 'SECONDARY',
    [styles.isPrimary]: containerStyle === 'PRIMARY'
  });

  useEffect(() => {
    if( isIntersecting ) {
      setTheme( theme );
    }
  }, [isIntersecting, setTheme, theme])

  return (
    <Tag {...other} className={className} ref={ref}>
      {children}
    </Tag>
  )
}

Section.defaultProps = {
  Tag: 'div',
  isCompact: false,
  isUnstyled: false
}

export default Section;
