import { useEffect, useState, useRef } from 'react';

const DEFAULTS = {
    root: null,
    rootMargin: '0px 0px -100%',
    threshold: [0]
};

function useIntersect(ref, options) {
    const [isIntersecting, setIntersecting] = useState(null);
    const observer = useRef(null);

    function handleIntersect( entries, observer ) {
        entries.forEach(entry => {
            setIntersecting(entry.isIntersecting);

            if( options && options.disconnectAfter && entry.isIntersecting ) {
                observer.disconnect();
            }
        });        
    }

    useEffect(() => {
        observer.current = new IntersectionObserver(handleIntersect, 
            { ...DEFAULTS, ...options }
        );

        observer.current.observe(ref.current);

        return () => observer.current.disconnect();
    });

    return isIntersecting;
}

export default useIntersect;