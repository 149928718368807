import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import AniLink from "gatsby-plugin-transition-link/AniLink";
// import styles from './Link.module.scss';

const Link = ({ children, ...other }) => {
    const isInPreview = typeof window !== 'undefined' && window.location.href.includes('/admin/');

    return isInPreview ? (
        <GatsbyLink onClick={e => document.body.click()} {...other}>{children}</GatsbyLink>
    ) : (
        <AniLink paintDrip hex="#fff" duration={.5} onClick={e => document.body.click()} {...other}>
            {children}
        </AniLink>
    )
}

Link.propTypes = {

};

export default Link;
