import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import classNames from 'classnames';
import { globalHistory } from '@reach/router';
import styles from './Dropdown.module.scss';

const Dropdown = ({
    toggle, 
    toggleClass,
    menuClass,
    children,
    Tag
}) => {
    const id = v4();
    const toggleRef = useRef(null);
    const dropdownRef = useRef(null);
    const historyRef = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const className = classNames( styles.dropdown, {
        [styles.dropdownActive]: isOpen
    }, menuClass);

    function handleDocumentClick( e ){
        if( !dropdownRef.current ) return;

        const isOutsideClick = !toggleRef.current.contains( e.target ) && !dropdownRef.current.contains( e.target );

        if ( isOutsideClick && isOpen ) {
            setOpen( false )
        }
    }

    function handleToggleClick( e ) {
        setOpen(!isOpen);
    }

    useEffect(() => {
        historyRef.current = globalHistory.listen(({ action }) => {
            if ( action === 'PUSH' ) {
                setOpen( false );
            }
        });

        return historyRef.current;
    }, []);

    useEffect(() => {
        document.addEventListener('mouseup', handleDocumentClick );

        return () => {
            document.removeEventListener('mouseup', handleDocumentClick );
        }
    });

    return (
        <Tag className={styles.container}>
            <button className={toggleClass} type="button" aria-controls={id} onClick={handleToggleClick} ref={toggleRef} aria-expanded={isOpen}>
                {toggle}
            </button>
        
            <div id={id} className={className} ref={dropdownRef}>
                { children }
            </div>
        </Tag>
    )
}

Dropdown.defaultProps = {
    Tag: 'div'
};

Dropdown.propTypes = {
    
};

export default Dropdown;