import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import Player from '@vimeo/player';
import useIntersect from '../../hooks/useIntersect';
import styles from './VideoBg.module.scss';

const VideoBg = ({ src, timestamps, placeholder, isLazy, ...other }) => {
    const containerRef = useRef(null);
    const playerRef = useRef(null);
    const [isPlaying, setPlaying] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const isIntersecting = useIntersect(containerRef, {
        rootMargin: '0px',
        disconnectAfter: true
    });
    const className = classNames(styles.wrapper, other.className, {
        [styles.isPlaying]: isPlaying
    });

    useEffect(() => {
        if( playerRef.current ) return;
        
        if( containerRef.current && ( isIntersecting || !isLazy ) ) {
            let t = 0;

            if ( timestamps && timestamps.length ) {
                t = timestamps[ Math.floor(Math.random() * timestamps.length )];
            }

            const player = playerRef.current = new Player( containerRef.current, {
                id: `https://player.vimeo.com/video/${src}`,
                width: 640,
                autopause: false,
                controls: false,
                loop: true,
                muted: true
            });

            player.setCurrentTime( t );
            player.on('play', () => setPlaying( true ) );
            player.on('loaded', () => setLoaded( true ) );

            player.play();    
        }
    });

    useEffect(() => {
        if( isPlaying ) {
            playerRef.current.play();    
        }
       
    }, [isPlaying])

    return (
        <div className={className} ref={containerRef}>
            {placeholder &&
                <img className={styles.placeholder} src={placeholder} alt="" loading="lazy"/>
            }
        </div>
    )
};

VideoBg.defaultProps = {
    timestamps: [],
    isLazy: false
};

export default VideoBg;
